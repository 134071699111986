import React from 'react';
import axios from 'axios';
import logo from '../logo.svg';

const HackButtonHttp: React.FC<{}> = () => {
//  const axios = require('axios');

  const onTap = () => {
    console.log('on tap')
    axios.get('http://ec2-52-68-246-84.ap-northeast-1.compute.amazonaws.com:8300/hack')
      .then(function (response) {
        console.log('hack!');
      })
      .catch(function (error){
        console.log('error');
        console.log(error);
      })
  };

  const rootPath = 'https://cdn.tan-t.tokyo/'
  const imagePath = 'magazine/image/images.jpeg'

  return (
    <>
      <div style={{ userSelect: 'none' }} onClick={onTap}>
        <img src={rootPath + imagePath} className="App-logo" alt="logo" />
      </div>
      {/*}<button onClick={onTap} >button</button>*/}
    </>
  );
}

export default HackButtonHttp;
